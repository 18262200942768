import React, { Component } from "react";
// import { styles } from "../../utils";
// import { FaReact, FaTwitter, FaFacebook } from "react-icons/fa";

import styled from "styled-components";

export default class Brands extends Component {
  state = {
    // icons: this.props.iconsArray
    //  [
    //   {
    //     id: 1,
    //     iconPath: "https://cdn.iconscout.com/icon/free/png-256/redux-283024.png"
    //   },
    //   {
    //     id: 2,
    //     iconPath:
    //       "https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png"
    //   },
    //   {
    //     id: 3,
    //     iconPath:
    //       "https://cdn.iconscout.com/icon/free/png-256/ethereum-16-646072.png"
    //   },
    //   {
    //     id: 4,
    //     iconPath:
    //       "https://freeicons.io/laravel/public/uploads/icons/png/18411663001536298193-original.png"
    //   },
    //   {
    //     id: 5,
    //     iconPath:
    //       "https://cdn.iconscout.com/icon/free/png-256/nodejs-6-569582.png"
    //   },
    //   {
    //     id: 6,
    //     iconPath:
    //       "https://cdn1.iconfinder.com/data/icons/cryptocurrency-blockchain-fintech-free/32/Cryptocurrency_blockchain_data-37-512.png"
    //   }
    // ]
  };
  render() {
    const iconMap = this.props.iconsArray.map((icon, index) => {
      // console.log(icon);
      const imgsrc = require(`../../utils/icon/sol_icons/${icon}.svg`);
      return (
        <div className="col s6 m3 l2 img-container" key={index}>
          <img
            src={imgsrc}
            key={index}
            className="brand-icon"
            alt="brandIcon"
          />
        </div>
      );
    });

    return (
      <BrandsWrapper>
        <div className="row">{iconMap}</div>
      </BrandsWrapper>
    );
  }
}
const BrandsWrapper = styled.div`
  /* use float: left to align items horizental instead of vertical
    float: left; */
  /* vertical-align: middle; */

  padding: 0rem;

  .img-container {
    margin-top: 2rem;
    /* width: auto; */
  }

  .brand-icon {
    width: 68px;
    height: 68px;
    /* box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.1); */
    /* box-shadow: 0 1px 0 #dfdedc, 0 2px 0 rgba(255, 255, 255, 0.5); */

    /* z-index: -3; */
    /* filter: drop-shadow(0 0 3px #333); */
  }
`;
