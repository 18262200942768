import React from "react";
import styled from "styled-components";
import { styles } from "../utils";

// for function base cpm we pass the props in {} like below
function Title({ title, message }) {
  return (
    <TitleWrapper>
      <h1 className="title">{title}</h1>
      <h3 className="message">{message}</h3>
      {/* <div className="underline" /> */}
    </TitleWrapper>
  );
}

function Title2({ title, message, titleColor, messageColor }) {
  const titlClassename =
    { titleColor } !== "" ? "title " + titleColor : "title";
  const messageClassename =
    { messageColor } !== "" ? "message " + messageColor : "message";

  return (
    <TitleWrapper2>
      <h1 className={titlClassename}>{title}</h1>
      <h3 className={messageClassename}>{message}</h3>
      {/* <div className="underline" /> */}
    </TitleWrapper2>
  );
}

/******************************/
//    Styled Components props
/******************************/
const TitleWrapper = styled.div`
/* background-image:transparent!important; */
/* background: transparent; */
  text-align: center; 
  padding:0rem 1.5rem;

  .title {
    ${styles.letterSpacing({ spacing: "0.15rem" })};
    font-size: 1.3rem;
    /* font-weight:bold; */
    text-transform: uppercase;
    color: ${styles.colors.mainDarkBlueColor}; 
    font-weight: 500;    
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
  }
  .message {
    /* background-image:transparent; */
    line-height:1.8rem;
      ${styles.letterSpacing({ spacing: "0.12rem" })};
      font-size: 0.9rem;
      color: ${styles.colors.mainSubtitleClr};
      margin-bottom:2.5rem;
      /* ${styles.textSndFont}; */
      
    }
    /* ${styles.transObject({})}; */
  /* .underline {
       height: 0.9px;           
      background-color: #e0e0e0;      
      width:40rem;
      margin:2rem auto;
  } */
    
  @media (min-width: 688px) {   
    .title {
      font-size: 2rem;  
    }
    .message {
      font-size: 1.1rem;
    }
  }

`;

/******************************/
//    TitleWrapper2 Wrapper
/******************************/
const TitleWrapper2 = styled(TitleWrapper)`
  padding: 0rem 4rem;
  .title {
    color: ${styles.colors.mainBlack};
  }
  .message {
    ${styles.letterSpacing({ spacing: "0.15rem" })};
    margin-bottom: 2.5rem;
  }
  .white-color {
    color: ${styles.colors.mainWhite}!important;
  }
  .black-color {
    color: ${styles.colors.mainTextClr}!important;
  }
  .grey-color {
    /* color: #aaa !important; */
    color: ${styles.colors.mainSubTextClr2}!important;
  }
`;
/******************************/
//    Default props
/******************************/
Title.defaultProps = {
  message: "Our message",
  title: "Our title"
};

export { Title, Title2 };
