import React from "react";
import PropTypes from "prop-types";
import { createGlobalStyle } from "styled-components";
import Navbar from "../components/00-navbar/Navbar";
import FixedFloating from "./FixedFloating";
import Footer from "./footer/Footer";
import "../utils/css/materialize.min.css";
import { styles, floatingBG } from "../utils/";
import styled from "styled-components";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Navbar />
      <HomeWrapper img={floatingBG}>{children}</HomeWrapper>
      <FixedFloating />
      <Footer />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

/*************************/
// Global Styles Component
/*************************/
const HomeWrapper = styled.div`
  background: url(${props => props.img}) 50% / cover no-repeat;
`;

const GlobalStyle = createGlobalStyle`

*{
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: "Roboto",sans-serif !important;
  /* border-box; when we add padding to element inside div for example its size remain the same   */
}
body{
  /* font-family: 'Courier New', Courier, monospace; */
  /* color: grey; */
  /* background:white; */
  
  /* background: #fff url(../utils/img/bg.jpg) center/cover fixed no-repeat; */
  
}

:root{
  /* use variable in css start with -- and when u want to use it,
  to use it write var(--) not supported on all browser*/
  --mainOrange:#f15202;
}
p{
    text-align: justify;
    color:${styles.colors.mainSubTextClr};    
        font-size: 1.1em;
    line-height: 1.5em;
    font-weight: 200;

}
  .paragraph-max-line1{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-height: 1.4rem; /* fallback */
    max-height: 1.4 * 4;
  }

  .paragraph-max-line{
    overflow:hidden; line-height: 1.4em; max-height: 4em; 
  }

.bgColor{
  /* color: "linear-gradient(to top left, rgb(13, 57, 138) 0 %, rgb(48, 145, 235) 100 %)";    */
  /* background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1)); */ 
}

/* 
.txtColor{
  color: rgb(14, 144, 228)!important;
  font - weight: 700;
  font - size: 1.5em;
} */
.divider-line {
  
    width: 15rem;
    margin:2rem auto;
    height: 30px; 
    border-style: solid; 
    border-color: #e0e0e0; 
    border-width: 2px 0 0 0; 
    border-radius: 20px; 
}

@media (min-width: 688px) {   
  .divider-line {
    width: 45rem;
    margin:1rem auto;
  }
}

.divider-line:before { 
  display: block; 
  content: ""; 
  height: 30px; 
  margin-top: -31px; 
  border-style: solid; 
  border-color: #e0e0e0; 
  border-width: 0 0 2px 0; 
  border-radius: 20px; 
}


.divider-line-normal {
      /* height: 0.9px;
      background-color: #e0e0e0;      
      width: relative; 
      margin:2rem auto;          */
}


/* blockquote {
      margin: 20px 0;
      padding-left: 1.5rem;
      border-left: 1.5px solid #e0e0e0;
    } */
    
.container-wide{
padding: 0rem 1.2rem;
}


`;

export default Layout;

/**
 * // const tempColor = "#f15202";
  /* background: ${tempColor}; */
/* background: var(--mainOrange);
 


  background: -webkit-gradient(linear, center top, center bottom, from(#ededed), to(#fff));
    background-image: linear-gradient(#ededed, #fff);
    border-radius: 12px;
    box-shadow: inset 0px 0px 1px 1px rgba(0,0,0,0.1);
    color: #222;
*/
