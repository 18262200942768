/** Source: https://demo.tutorialzine.com/2015/01/freebie-5-responsive-footer-templates/footer-distributed-with-address-and-phones.html */
import React, { Component } from "react";
import styled from "styled-components";
import { styles, rosepetalsIcon } from "../../utils/";
import { StaticQuery, graphql } from "gatsby";
import GetScoialIcons from "./GetScoialIcons";
import { MdLocationOn, MdLocalPhone, MdMailOutline } from "react-icons/md";

export default class Footer extends Component {
  render() {
    return (
      <FooterWrapper img={rosepetalsIcon}>
        <StaticQuery
          query={FOOTERQUERY}
          render={data => {
            const footerInfo = data.footerPageQuery.edges[0];
            const {
              fLeftTitle,
              fLeftSubtitle,
              fLeftCopyright,
              fCenterMobile,
              fCenterEmail,
              fRightTitle,
              fRightDescription
            } = footerInfo.node;

            return (
              <footer className="footer-distributed">
                {/* Left section */}
                <div className="footer-left">
                  <h3>{fLeftTitle}</h3>
                  <p className="footer-links">{fLeftSubtitle}</p>
                  <p className="footer-company-name">{fLeftCopyright}</p>
                </div>
                {/* Center section */}
                <div className="footer-center">
                  <div>
                    <MdLocationOn className="icon md-location" />
                    {/* <p>{fCenterAddress}</p> */}
                    <p>
                      <span>Bredgatan 11</span> Helsingborg, Sweden
                    </p>
                  </div>
                  <div>
                    <MdLocalPhone className="icon md-phone" />
                    <p>{fCenterMobile}</p>
                  </div>
                  <div>
                    <MdMailOutline className="icon md-email" />
                    <p>
                      <a href="mailto:support@xbound.se">{fCenterEmail}</a>
                    </p>
                  </div>
                </div>
                {/* Right section */}
                <div className="footer-right">
                  <p className="footer-company-about">
                    <span>{fRightTitle}</span>
                    {fRightDescription.fRightDescription}
                  </p>

                  <div className="footer-icons">
                    <GetScoialIcons />
                    {/* {this.state.icons.map(icon => (
                      <a
                        href={icon.path}
                        className="faicon"
                        key={icon.id}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {icon.icon}
                      </a>
                    ))} */}
                  </div>
                </div>
              </footer>
            );
          }}
        />
      </FooterWrapper>
    );
  }
}

/**************************************/
//          Graphql Constants
/**************************************/
const FOOTERQUERY = graphql`
  {
    footerPageQuery: allContentfulFooter {
      edges {
        node {
          fLeftTitle
          fLeftSubtitle
          fLeftCopyright
          fCenterAddress
          fCenterMobile
          fCenterEmail
          fRightTitle
          fRightDescription {
            fRightDescription
          }
        }
      }
    }
    socialsData: allContentfulSocial(sort: { fields: [index], order: ASC }) {
      edges {
        node {
          index
          socialIconTitle
          socailIconLink
          iconLibrary
          iconName
        }
      }
    }
  }
`;

/**************************************/
//  BannerButton Styled Components
/**************************************/
const FooterWrapper = styled.div`
  /* ${styles.blueGradient}; */
  /* Auto fit columns as a template to use for any cards or content that we want to make it responsive */
  /* padding: 2rem 2rem 0.2rem 2rem; */
background:url(${props => props.img}) center/cover fixed no-repeat;
  /* margin: 3rem 0rem 0rem 0rem; */
  
  .footer-distributed {
    /* ${styles.blueGradient}; */
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 3rem 2rem 1.2rem 3rem;
    /* margin-top: 80px; */
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
  }

  /* Footer left */
  .footer-distributed .footer-left {
    padding-top:2rem;    
    width: 30%;
  }

  /* The company logo */
  .footer-distributed h3 {
    color: #ffffff;
    /* font: normal 30px "Cookie", cursive; */
    font-size: 18px;
    /* text-shadow: 1px 1px 0 #eee;       */
    margin: 0;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
  }

  .footer-distributed h3 span {
    color: #ccc;
  }

  /* Footer links */
  .footer-distributed .footer-links {
    color: #ffffff;
    margin: 20px 0 12px;
    font-size:14px;
    padding: 0;
  }

  .footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
  }

  .footer-distributed .footer-company-name {
    color: #ccc;
    font-size: 11px;
    font-weight: normal;
    margin: 0;
  }

  /* Footer Center */
  .footer-distributed .footer-center {
    width: 35%;
  }

  .footer-distributed .footer-center .icon {
    /* background-color: #33383b; */
    color: #ff4081; 
    /* ${styles.colors.mainPinkRedClr};  */
    /* color: #ffffff; */
    /* font-size: 25px; */
    width: 32px;
    height: 32px;
    /* border-radius: 50%; */
    text-align: center;
    /* line-height: 42px; */
    margin: 10px 15px;
    vertical-align: middle;
  }

  .footer-distributed .footer-center .md-email {
    /* font-size: 17px !important; */
    /* line-height: 38px; */
    width: 30px;
    height: 30px;
  }
.footer-distributed .footer-center .md-phone {
    /* font-size: 17px !important; */
    /* line-height: 38px; */
    width: 31px;
    height: 31px;
  }
  .footer-distributed .footer-center p {
    font-size:1rem;
    display: inline-block;
    color: #eee;
    vertical-align: middle;
    margin: 0;
  }

  .footer-distributed .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
  }

  .footer-distributed .footer-center p a {
    color: #eee;
    text-decoration: none;
  }

  /* Footer Right */
  .footer-distributed .footer-right {
    width: 30%;
  }

  .footer-distributed .footer-company-about {
    line-height: 20px;
    color: #ccc;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
  }

  .footer-distributed .footer-company-about span {
    display: block;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .footer-distributed .footer-icons {
    margin-top: 10px;
  }

  .footer-distributed .footer-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    /* background-color: #33383b; */
    border-radius: 2px;

    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;

    margin-right: 3px;
    padding-bottom: 5px;
      &:hover {
      /* light pink */
      color: #ff4081;
      transform: scale(1.16);
    }
  }

  /* If you don't want the footer to be responsive, remove these media queries */

  @media (max-width: 880px) {    
    .footer-distributed {
      font: bold 14px sans-serif;
      padding: 1rem 2.5rem 1.2rem 2.5rem;
    }
    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right {
      display: block;
      margin: 0.6rem auto 1.9rem;      
      width: 100%;
    }
    .footer-distributed .footer-left {
      padding-top:0rem;        
    }
    .footer-distributed .footer-right {
      margin-bottom:0.2rem;      
    }    

    .footer-distributed .footer-center .icon {
      margin-left: 0;
    }
  }
`;
