import styled from "styled-components";

export const Section = styled.section`
  /* 2 rem top buttom and 0rem left right */
  /* background-image: transparent; */
  /* background-color: rgba(0, 0, 0, 0.1); */
  background: transparent;
  padding: 1.2rem 0;
  /* 90% of the screen view width vw */
  width: 90vw;
  /* 0 top down, auto left right to center it use in case of block */
  margin: 0.5rem auto;
  text-align: center;
`;
