import * as styles from "./styles";

import TestComp from "./TestComp";
import {
  HomeHeader,
  ContactHeader,
  ServicesHeader,
  SolutionsHeader,
  BlogHeader,
  CareersHeader,
  ParallexHeader
} from "../components/01-hero/Header";
/**  Solutions Icons  */

import etherIcon from "./icon/sol_icons/etherIcon.svg";
import reactIcon from "./icon/sol_icons/reactIcon.svg";
import reduxIcon from "./icon/sol_icons/reduxIcon.svg";
import nodejsIcon from "./icon/sol_icons/nodejsIcon.svg";
import gatsbyIcon from "./icon/sol_icons/gatsbyIcon.svg";
import avatar1 from "./icon/career_icons/avatar1.jpeg";
import avatar2 from "./icon/career_icons/avatar2.jpeg";
import graphqlIcon from "./icon/sol_icons/graphqlIcon.svg";
import mailIcon from "./icon/mail.svg";
import rosepetalsIcon from "./img/rose-petals.svg";
import floatingBG from "./img/floatingBG.svg";
// import parallex1Icon from "./img/parallex1.svg";

/**  Services Icons  */

import fullstackIcon from "./icon/fullstackIcon.svg";
import bchainIcon from "./icon/bchainIcon.svg";

// import startupIcon from "./icon/startupIcon.svg";
// import teamIcon from "./icon/teamIcon.svg";
import auditingIcon from "./icon/auditingIcon.svg";
import ParallexImage from "./ParallexImage";
// import parallexImg from "./parallexImg.jpg";

// import SolutionCard from "../components/03-solutions/SolutionCard";
// import ServiceCard from "../components/02-services/ServiceCard";
import Social from "../components/Social";
import { BannerDefault, Banner } from "../components/01-hero/Banner";
import {
  BannerButton,
  SectionButton,
  NavButton,
  NavButtonStyle2,
  DefaultButton
} from "./Button";
import { Section } from "./Section";
import { Title, Title2 } from "./Title";
// import BlogList from "../components/04-blog/BlogList";

/** instead of including the absolute path to the files inside this folder util
 *  we create this index.js file which will be called automatically in the include
 *  and here we treat it like a placeholder of all the functions and classes inside
 *  util folder (e.g import {HomeHeader} from '../utils/')
 */
export {
  TestComp,
  fullstackIcon,
  avatar1,
  avatar2,
  floatingBG,
  bchainIcon,
  auditingIcon,
  styles,
  HomeHeader,
  CareersHeader,
  ContactHeader,
  ServicesHeader,
  SolutionsHeader,
  BlogHeader,
  ParallexHeader,
  Banner,
  BannerDefault,
  BannerButton,
  SectionButton,
  DefaultButton,
  NavButton,
  NavButtonStyle2,
  Section,
  Title,
  Title2,
  Social,
  ParallexImage,
  etherIcon,
  reactIcon,
  reduxIcon,
  nodejsIcon,
  gatsbyIcon,
  graphqlIcon,
  mailIcon,
  rosepetalsIcon
};
