import React, { Component } from "react";
import NavbarHeader from "./NavbarHeader";
import NavbarLinks from "./NavbarLinks";
import NavbarIcons from "./NavbarIcons";
import { styles } from "../../utils/";
import styled from "styled-components";

export default class Navbar extends Component {
  state = {
    navbarOpen: false
  };
  handleNavbar = () => {
    this.setState(() => {
      return { navbarOpen: !this.state.navbarOpen };
    });
  };
  render() {
    return (
      <NavWrapper className="container-wide">
        {/* NavbarHeader contains the logo and the menu toggle icon */}
        <NavbarHeader handleNavbar={this.handleNavbar} />
        {/* NavbarLinks contains the page links */}
        <NavbarLinks navbarOpen={this.state.navbarOpen} />
        {/* NavbarIcons contains the social media icons or Get Quote button */}
        <NavbarIcons />
      </NavWrapper>
    );
  }
}
/************************************/
/**         NavWrapper SC          */
/************************************/
const NavWrapper = styled.header`
  overflow: hidden;
  background-color: #fff;
  /* background-color: #333; */
  /* background: transparent; */
  /* Set the navbar to fixed position */
  position: fixed;
  top: 0; /* Position the navbar at the top of the page*/
  width: 100%; /* Full width */
  z-index: 15;

  background-color: ${styles.colors.mainLightGreybgClr};
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;
