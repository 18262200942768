import React from "react";
import { StaticQuery, graphql } from "gatsby";
// import {FaInstagram,FaTwitter,FaFacebook,FaMedium,FaLinkedin} from "react-icons/fa";
import styled from "styled-components";
import { styles, rosepetalsIcon } from "../utils/";

function handleReactIcon(libName) {
  let MaterialDesign = "";
  if (libName === "fa") {
    MaterialDesign = require("react-icons/fa");
  } else if (libName === "io") {
    MaterialDesign = require("react-icons/io");
  } else if (libName === "io") {
    MaterialDesign = require("react-icons/io");
  } else if (libName === "md") {
    MaterialDesign = require("react-icons/md");
  } else if (libName === "ti") {
    MaterialDesign = require("react-icons/ti");
  }
  //  else if (libName === "fi") {
  //   MaterialDesign = require("react-icons/fi");
  // } else if (libName === "go") {
  //   MaterialDesign = require("react-icons/go");
  // } else if (libName === "gi") {
  //   MaterialDesign = require("react-icons/gi");
  // } else if (libName === "wi") {
  //   MaterialDesign = require("react-icons/wi");
  // } else if (libName === "di") {
  //   MaterialDesign = require("react-icons/di");
  // }
  return MaterialDesign;
}

export default function Social({ title, subTitle }) {
  return (
    <SocialWrapper
      className="section section-follow center"
      img={rosepetalsIcon}
    >
      <div className="row">
        <div className="col s12 ">
          <div className="container">
            <div className="title">
              <h3>{title}</h3>
              <p>{subTitle}</p>
            </div>
            <StaticQuery
              query={SOCIALSERVICE}
              render={data => {
                const socialDetail = data.socialsData.edges;
                return socialDetail.map((social, index) => {
                  let iconName = social.node.iconName;
                  let iconLibName = social.node.iconLibrary;
                  let MaterialDesign = handleReactIcon(iconLibName);
                  let mdIcon = MaterialDesign[iconName];
                  return (
                    <a
                      key={index}
                      className="faicon"
                      href={
                        social.node.socailIconLink ||
                        "https://www.facebook.com/xbound.se/"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Socials icons for xbound"
                    >
                      {React.createElement(mdIcon, {
                        className: "icon"
                      })}
                    </a>
                  );
                });
              }}
            />
          </div>
        </div>
      </div>
    </SocialWrapper>
  );
}

/**************************************/
//          Graphql Constants
/**************************************/
const SOCIALSERVICE = graphql`
  {
    socialsData: allContentfulSocial(sort: { fields: [index], order: ASC }) {
      edges {
        node {
          index
          socialIconTitle
          socailIconLink
          iconLibrary
          iconName
        }
      }
    }
  }
`;

const SocialWrapper = styled.section`
/* ${styles.blueGradient}; */
/* background: ${styles.colors.mainWhitebgClr}; */
  background: url(${props => props.img}) center/cover fixed no-repeat;
  margin: 0rem 0rem 1.6rem 0rem;

  .icon {
    font-size: 3rem;
    margin: 25px 10px 10px 10px;
    /* color: #fff; */
    color: ${styles.colors.mainWhitebgClr};
    /* color: #ff4081; light pink */
    &:hover {
      /* light pink */
      color: #ff4081;
      transform: scale(1.16);
    }
  }
  .title h3 {
    /* color: #ff4081; */
    /* color: ${styles.colors.mainWhitebgClr}; */
     color:#fff;
    font-size:2.1rem;
    margin: 1.4rem auto;
    text-align: center;
    align-items: center;
    /* font-weight: 600; */
    letter-spacing: 0.3rem;
    text-transform:uppercase;
  }
  .title p {
     color:#ccf;
    margin: 1rem auto;
    text-align: center;
    font-size:1rem;
  }
  @media (max-width: 880px) {   
    h3{
      font-size:1.6rem !important;
    }
    .icon {
      font-size: 2.6rem;
      }
    }
  `;
