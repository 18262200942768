import styled from "styled-components";
import { styles } from "../utils";

//   Here we are not using any state class or function
//   that is why we only exports different buttons here

/**************************************/
//  BannerButton Styled Components
/**************************************/

const BannerButton = styled.button`
  /* defined as block to be able to control it   */
  /* To horizontally center a block element (like div), use margin: auto */
  display: block;
  color: ${styles.colors.mainBlueColor};
  background: transparent;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-size: 1.08rem;
  letter-spacing: 0.25rem;
border-radius: 5px;
  ${styles.border({ color: `${styles.colors.mainBlueColor}` })};
  /* ${styles.transObject({ time: "0.3s" })}; */

  &:hover {
    background: ${styles.colors.mainBlueColor};
    color: ${styles.colors.mainWhite};
    cursor: pointer;
  }
`;

/**************************************/
//  SectionButton Styled Components
/**************************************/
const SectionButton = styled(BannerButton)`
  ${styles.border({ color: `${styles.colors.mainBlack}` })};
  color: ${styles.colors.mainBlack};
  &:hover {
    background: ${styles.colors.mainBlack};
    color: ${styles.colors.mainYellow};
    ${styles.transObject({})};
  }
`;
/**************************************/
//  NavButton Styled Components
/**************************************/
const NavButtonStyle2 = styled.button`
  /* 009fc8 Blue ,7ed957 Green , cb6ce6 pink*/
  display: block;
  font-size: 1.1rem;
  background-color: ${styles.colors.mainBlueColor};

  ${styles.border({ color: `${styles.colors.mainBlueColor}` })};
  color: ${styles.colors.mainWhite};
  ${styles.transObject({})};
  &:hover {
    background: ${styles.colors.mainWhite};
    color: ${styles.colors.mainBlueColor};
    ${styles.transObject({})};
  }
`;
/**************************************/
//  NavButton Styled Components
/**************************************/
const NavButton = styled.button`
  /* 009fc8 Blue ,7ed957 Green , cb6ce6 pink*/
  padding: 0.65em 1.5em;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.55s ease;

  background: ${styles.colors.mainPinkRedClr};
  color: ${styles.colors.mainWhite};
  border: 1px solid ${styles.colors.mainPinkRedClr};

  box-shadow: 1px 2px 15px -8px rgba(0, 0, 0, 0.7);
  /* border-radius: 5px; */
  &:hover {
    cursor: pointer;
    background: none;
    color: ${styles.colors.mainPinkRedClr};
    border: 1px solid ${styles.colors.mainPinkRedLightClr};
  }
`;

/**************************************/
//  NavButton Styled Components
/**************************************/
const DefaultButton = styled.button`
  margin: 0rem auto;
  padding: 5px 15px;
  text-decoration: none;
  background: transparent;
  border: 2px solid #f27c7c;
  color: #f27c7c;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: background 0.3s ease;
  -moz-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease;
  -webkit-box-shadow: 2px 2px 0 #f27c7c;
  -moz-box-shadow: 2px 2px 0 #f27c7c;
  -ms-box-shadow: 2px 2px 0 #f27c7c;
  box-shadow: 2px 2px 0 #f27c7c;
  &:hover {
    box-shadow: none;
    top: 2px;
    left: 2px;
    -webkit-box-shadow: 2px 2px 0 transparent;
    -moz-box-shadow: 2px 2px 0 transparent;
    -ms-box-shadow: 2px 2px 0 transparent;
    box-shadow: 2px 2px 0 transparent;
  }
`;

export {
  BannerButton,
  SectionButton,
  DefaultButton,
  NavButton,
  NavButtonStyle2
};
