import React from "react";
import styled from "styled-components";
import { styles } from "../utils/";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
// import { FaTwitter, FaFacebook, FaLinkedin, FaMedium } from "react-icons/fa";
// import { IoMdMore, IoMdArrowRoundUp, FaShare IoIosCellular } from "react-icons/io";
import { IoMdArrowRoundUp } from "react-icons/io";

function handleReactIcon(libName) {
  let MaterialDesign = "";
  if (libName === "fa") {
    MaterialDesign = require("react-icons/fa");
  } else if (libName === "io") {
    MaterialDesign = require("react-icons/io");
  } else if (libName === "io") {
    MaterialDesign = require("react-icons/io");
  } else if (libName === "md") {
    MaterialDesign = require("react-icons/md");
  } else if (libName === "ti") {
    MaterialDesign = require("react-icons/ti");
  }
  //  else if (libName === "fi") {
  //   MaterialDesign = require("react-icons/fi");
  // } else if (libName === "go") {
  //   MaterialDesign = require("react-icons/go");
  // } else if (libName === "gi") {
  //   MaterialDesign = require("react-icons/gi");
  // } else if (libName === "wi") {
  //   MaterialDesign = require("react-icons/wi");
  // } else if (libName === "di") {
  //   MaterialDesign = require("react-icons/di");
  // }
  return MaterialDesign;
}

export default function FixedFloating() {
  return (
    <FloatingWrapper>
      <Link to="/" className="float" id="menu-share">
        <IoMdArrowRoundUp className="fa fa-share my-float" />
      </Link>
      <ul>
        <StaticQuery
          query={SOCIALSERVICE}
          render={data => {
            const socialDetail = data.socialsData.edges;
            return socialDetail.map((social, index) => {
              let iconName = social.node.iconName;
              let iconLibName = social.node.iconLibrary;
              let MaterialDesign = handleReactIcon(iconLibName);
              let mdIcon = MaterialDesign[iconName];
              return (
                <li key={index}>
                  <a
                    className="li-link"
                    href={social.node.socailIconLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Socials icons for xbound"
                  >
                    {React.createElement(mdIcon, {
                      className: "fa my-float"
                    })}
                  </a>
                </li>
              );
            });
          }}
        />
      </ul>
    </FloatingWrapper>
  );
}

/**************************************/
//          Graphql Constants
/**************************************/
const SOCIALSERVICE = graphql`
  {
    socialsData: allContentfulSocial(sort: { fields: [index], order: ASC }) {
      edges {
        node {
          index
          socialIconTitle
          socailIconLink
          iconLibrary
          iconName
        }
      }
    }
  }
`;

const FloatingWrapper = styled.div`
  .float {
    position: fixed;
    width: 55px;
    height: 55px;
    bottom: 30px;
    right: 30px;
    /* background-color: ${styles.colors.mainBlueColor}; */
    background:rgba(51,121,221,0.7);
    color: #fff;
    border-radius: 50px;
    text-align: center;

    /* box-shadow: 2px 2px 3px #999; */
    z-index: 1000;
    animation: bot-to-top 2s ease-out;
  }

  ul {
    position: fixed;
    right: 30px;
    padding-bottom: 20px;
    bottom: 80px;
    z-index: 100;
  }

  ul li {
    list-style: none;
    margin-bottom: 10px;
  }

  ul li a {
    background-color: ${styles.colors.mainBlueColor};
    color: #fff;
    border-radius: 50px;
    text-align: center;
    /* box-shadow: 2px 2px 3px #999; */
    width: 55px;
    height: 55px;
    display: block;
  }

  ul:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }

  .my-float {
    font-size: 22px;
    margin-top: 18px;
  }
  .float:hover {
    background-color: ${styles.colors.mainBlueColor};
    /* transform: rotate(360deg); */
  }

  a#menu-share + ul {
    visibility: hidden;
  }

  a#menu-share:hover + ul {
    visibility: visible;
    animation: scale-in 0.5s;
  }

  a#menu-share i {
    animation: rotate-in 0.5s;
  }

  a#menu-share:hover > i {
    animation: rotate-out 0.5s;
  }

  @keyframes bot-to-top {
    0% {
      bottom: -40px;
    }
    50% {
      bottom: 40px;
    }
  }

  @keyframes scale-in {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes rotate-in {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes rotate-out {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;
