import React from "react";
import styled from "styled-components";
// import { styles } from "../utils";
import defaultImg from "./img/bcg/servicesbcg.jpg";

function ParallexImage({ img }) {
  return (
    <ParalImageWrapper>
      <div className="full-width-div">
        <img src={img} alt="tst" />
      </div>
    </ParalImageWrapper>
  );
}

/******************************/
//    Styled Components props
/******************************/
const ParalImageWrapper = styled.div`
  /* "cover" value keeps the aspect ratio, and some part of the background image may be clipped: */
  img {
    height: 40vh;
    width: 100%;
    object-fit: cover;
  }
  .full-width-div {
    height: 40vh;
    position: relative;
  }
`;
ParallexImage.defaultProps = {
  img: defaultImg
};
export default ParallexImage;
