import React from "react";
import { StaticQuery, graphql } from "gatsby";

export default function GetScoialIcons() {
  return (
    <StaticQuery
      query={FOOTERQUERY}
      render={data => {
        const socialDetail = data.socialsData.edges;
        return socialDetail.map((social, index) => {
          let iconName = social.node.iconName;
          let iconLibName = social.node.iconLibrary;
          let MaterialDesign = handleReactIcon(iconLibName);
          let mdIcon = MaterialDesign[iconName];
          return (
            <a
              key={index}
              className="faicon"
              href={social.node.socailIconLink}
              target="_blank"
              aria-label="Socials icons for xbound"
              rel="noopener noreferrer"
            >
              {React.createElement(mdIcon, {
                className: "icon"
              })}
            </a>
          );
        });
      }}
    />
  );
}
function handleReactIcon(libName) {
  let MaterialDesign = "";
  if (libName === "fa") {
    MaterialDesign = require("react-icons/fa");
  } else if (libName === "io") {
    MaterialDesign = require("react-icons/io");
  } else if (libName === "io") {
    MaterialDesign = require("react-icons/io");
  } else if (libName === "md") {
    MaterialDesign = require("react-icons/md");
  } else if (libName === "ti") {
    MaterialDesign = require("react-icons/ti");
  }
  //  else if (libName === "fi") {
  //   MaterialDesign = require("react-icons/fi");
  // } else if (libName === "go") {
  //   MaterialDesign = require("react-icons/go");
  // } else if (libName === "gi") {
  //   MaterialDesign = require("react-icons/gi");
  // } else if (libName === "wi") {
  //   MaterialDesign = require("react-icons/wi");
  // } else if (libName === "di") {
  //   MaterialDesign = require("react-icons/di");
  // }
  return MaterialDesign;
}

/**************************************/
//          Graphql Constants
/**************************************/
const FOOTERQUERY = graphql`
  {
    socialsData: allContentfulSocial(sort: { fields: [index], order: ASC }) {
      edges {
        node {
          index
          socialIconTitle
          socailIconLink
          iconLibrary
          iconName
        }
      }
    }
  }
`;
