import React from "react";
import styled from "styled-components";
import { styles } from "../../utils";
// import logo from "../utils/img/icon-512x512.png";
import Brands from "./Brands";

/******************************/
//   Home Banner
/******************************/

function Banner({
  slogan,
  title,
  subtitle,
  iconsArray,
  description,
  children
}) {
  return (
    <BannerWrapper>
      <blockquote className="title-div">
        {/* <span style={{ color: "red", fontSize: "1.3rem" }}>
          UNDER CONSTRUCTION (We are lunching very soon, stay tuned)
        </span>
        <br /> */}
        <span className="slogan-text">{slogan}</span>
        {/* <h2 className="title">{title}</h2> */}
        <h2 className="title">Xbound</h2>
        <span className="subtitle">{subtitle}</span>
        <div className="text-paragraph">
          <p>{description}</p>
        </div>
      </blockquote>
      <Brands iconsArray={iconsArray} />

      {children}
    </BannerWrapper>
  );
}

/******************************/
//   BannerDafault
/******************************/

function BannerDefault({ title, subtitle, children }) {
  return (
    <BnrDefaultWrapper>
      <blockquote className="title-div">
        <h1 className="title">{title}</h1>
        <p className="subtitle">{subtitle}</p>
        {children}
      </blockquote>
    </BnrDefaultWrapper>
  );
}

/******************************/
//   CSS Styled Components
/******************************/
const BannerWrapper = styled.div`
  margin-left: 1.5rem;
  /* for small screen */
  width: 70%;

  .title {
    color: ${styles.colors.mainBlack};
    ${styles.textFstFont};
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 0.7px 0.7px ${styles.colors.mainGrey};
    ${styles.letterSpacing({ spacing: "0.3rem" })};
    margin: 0.5rem auto 0.3rem;
    
  }

  .slogan-text {
    color: ${styles.colors.mainSubTextClr};
    ${styles.textSndFont};
    font-size: 0.8rem;
    text-transform: capitalize;
  }
  
  .subtitle {
    padding-top:0.9rem;
    color: ${styles.colors.mainBlack};
    ${styles.textSndFont};
    ${styles.letterSpacing({ spacing: "0.1rem" })};
    font-size: 0.95rem;
    line-height:1.55rem;
    /* text-transform: uppercase; */

  }
  .text-paragraph {
    margin-top: 1.1rem;
    margin-bottom: 3rem;
    font-size: 0.9rem;
    word-spacing: 0.2rem;
    color: ${styles.colors.mainSubTextClr};
    line-height: 1.4rem;
    text-align: justify;
  }

  blockquote {
    margin: 20px 0;
    padding-left: 1.5rem;
    /* border-left: 1.5px solid ${styles.colors.mainBlack}; */
    border-left: 1.5px solid rgba(20, 20, 20, 0.3);;
    padding: 0rem 2rem 0rem 2rem;
  }

  .logo-img {
    height: 7rem;
    width: 7rem;
  }
  .logo-div {
    padding: 0rem 1rem;
  }

  /* The order is very important Top-Bottom, means the below items will override all the above items */
  @media (min-width: 768px) {
    /* To justifiy the text on lines instead of one line that takes the whole screen */
    width: 50%;
    margin-left: 3rem;
    .logo-img {
      height: 6rem;
      width: 6rem;
    }
    .title {
    font-size: 3rem;        
  }
  }

`;

const BnrDefaultWrapper = styled(BannerWrapper)`
  .title {
    color: ${styles.colors.mainWhite};
    font-size: 1.8rem;
  }
  .subtitle {
    color: ${styles.colors.mainLightGreybgClr};
    /* text-transform: capitalize; */
    font-size: 0.99rem;
  }
  blockquote {
    margin: 20px 0;
    padding-left: 1.5rem;
    border-left: 1.5px solid rgba(200, 200, 200, 0.6);
  }
  @media (min-width: 768px) {
    .title {
      font-size: 2.6rem;
    }
  }
`;

Banner.defaultProps = {
  title: "default title",
  sub: "default subtitle"
};

export { Banner, BannerDefault };
/* @media (min-width: 768px) {
   
  }
@media(min - width: 992px) {
   
} */
