export const colors = {
  mainWhite: `#fff`,
  mainBlack: `#262a2e`,
  mainYellow: `#d2aa5c`,
  mainYellow2: `#F2AF29`,
  mainGrey: `#474747`,
  mainWhitebgClr: `#E7EBEE`,
  mainLightGreybgClr: `#f8f9fb`,
  mainTextClr: `#2F3135`,
  mainSubTextClr: `#696969`,
  // mainSubTextClr: `#696969`,
  mainSubtitleClr: `#595959`,
  // mainSubtitleClr: `rgba(50, 50, 50, 0.8)`,
  mainSubTextClr2: `#777`,
  mainBlueColor: `#1476f2`,
  mainDarkBlueColor: `#1476f2`,
  mainGrybgClr: `#e7e7e7`,
  mainPinkRedClr: `#f50057`,
  mainPinkRedLightClr: `#ff4081`
};
/*
Nice Blue gradiant that takes the whole width

  background: #000046;
  background: -webkit-linear-gradient(to right, #1cb5e0, #000046);
  background: linear-gradient(to right, #1cb5e0, #000046);

// ==================
  mainBlueColor: `#38b6ff`,
blur red from card 
 background: rgba(200,38,29,0.7); */
// ==================
// good combination of gray background and icon color
// background - color: rgb(241, 241, 241);
// color: rgb(145, 145, 145);
// ==================
// background: linear - gradient(to top left, rgb(13, 57, 138) 0 %, rgb(48, 145, 235) 100 %);
//   /* background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1)); */
/** Logo Colors
mainTextColor: `#65768e`,
  mainBlueColor: `#38b6ff`,
    mainWhiteColor: `#f1f2f2`,
      mainSubTextColor: `#b6bdce`
Logo Color dark gray 65768e, Blue 38b6ff, b6bdce: light grayq, mainWhite as per logo f1f2f2,d9d9d9 */

// background: ##E7EBEE, mainFontColor:#2F3135, subtext:#696969

// export const textFstFont = `font-family:'Courier New', Courier , monospace;`;

// font - family:
export const textFstFont = `font-family:'Courier New', Courier, monospace;`;
export const textSndFont = `font-family:'Open Sans Bold', sans - serif;`;
export const textThrdFont = `font-family:'Caveat', cursive ;`;

export const blueGradientOrginal =
  "background: linear-gradient(to top right, #1cb5e0, #000046);";
export const blueGradient2 =
  "background-image: radial-gradient(circle, #1476f2, #1e56c2, #193995, #0c1e6a, #010142);";
/** Joomla */
export const blueGradientJoomla =
  "background: linear-gradient(to right, #1e3c72, #2a5298);";
/** Clearsky */
export const blueGradient =
  "background: linear-gradient(to right, #005c97, #363795);";
/** PinotNoir */
export const blueGradientPinotNoir =
  "background: linear-gradient(to right, #1569a8, #5995C3);";
/** PinotNoir */
// export const blueGradientPinotNoir =
//   "background: linear-gradient(to right, #4b6cb7, #182848);";

// export const blueGradient =
//   "background: linear-gradient(to right, #1cb5e0, #000046);";
export const transDefault = "transition:all 0.5s ease-in-out";
// in case of function if we want to use the default value of the attribute
//we still need to write undefined (transFunction(undefined,'0.4s'))
//or transFunction();
export const transFunction = (
  property = "all",
  time = "0.5s",
  type = "linear"
) => {
  return `transition:${property} ${time} ${type}`;
};
// in case of object and unlike function if we want to use the default value
// of the attribute we only pass the changed attributes otherwise leave it empty
//(transObject({}) or transObject({time:'1s'}) )
export const transObject = ({
  property = "all",
  time = "0.5s",
  type = "ease-in-out"
}) => {
  return `transition: ${property} ${time} ${type}`;
};

export const transition = ({
  property = "all",
  time = "0.5s",
  type = "ease-in-out"
}) => {
  return `transition: ${property} ${time} ${type}`;
};

export const border = ({
  width = "0.15rem",
  type = "solid",
  color = "white"
}) => {
  return `border:${width} ${type} ${color}`;
};
// export const animateBotToTop = ({
//   property = "bot-to-top",
//   time = "1s",
//   type = "ease-out"
// }) => {
//   return `transition: ${property} ${time} ${type}`;
// };

export const letterSpacing = ({ spacing = "0.1rem" }) => {
  return `letter-spacing:${spacing}`;
};
