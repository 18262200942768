import React, { Component } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { styles, NavButton } from "../../utils/";
export default class NavbarLinks extends Component {
  state = {
    Links: [
      {
        id: 0,
        path: "/",
        name: "home"
      },
      {
        id: 1,
        path: "/services/",
        name: "services"
      },
      {
        id: 2,
        path: "/solutions/",
        name: "solutions"
      },

      {
        id: 3,
        path: "/blogs/",
        name: "blog"
      },
      {
        id: 4,
        path: "/careers/",
        name: "careers"
      },
      {
        id: 5,
        path: "/contact/",
        name: "contact"
      }
    ]
  };

  render() {
    const LinkList = this.state.Links.map((item, index) => {
      return (
        <li key={item.id}>
          <Link to={item.path} className="nav-link">
            {item.name}
            <div
              className={index + 1 < this.state.Links.length ? "divider" : ""}
            />
          </Link>
        </li>
      );
    });
    return (
      <LinkWrapper navOpen={this.props.navbarOpen}>
        {LinkList}
        <li>
          <Link to="/quote/" className="quote-li">
            {/* <button className="btn1">Get Quote</button> */}
            <NavButton>Get Quote</NavButton>
          </Link>
        </li>
      </LinkWrapper>
    );
  }
}
/******************************/
//   CSS Styled Components
/******************************/
const LinkWrapper = styled.ul`
  /*1.  Small Screen */
  /* UL style first for the <768px screen:
 props passed to styled components. Here we make the transaction of opening the menubar by increasing the height of it, beside some transation for smoth movement  
*/

  height: ${props => (props.navOpen ? "300px" : "0px")};
  overflow: hidden;
  ${styles.transObject({ time: "0.4s" })};
  /* hide the stacked links untill we change the display to flex on bigger screen*/

  .quote-li {
    display: block;
    padding: 0.5rem 0.7rem 0.5rem 2.7rem;
  }

  .nav-link {
    display: block;
    text-decoration: none;
    font-family: Roboto;
    font-size: 0.95rem;
    /* text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2); */
    padding: 0.5rem 0.7rem 0.5rem 2.7rem;
    color: ${styles.colors.mainGrey};
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    line-height: 1.7rem;
    /* transition: all 0.2s linear; */
    /* nested hover of the nav-link */
    &:hover {
      background: ${styles.colors.mainBlueColor};
      color: ${styles.colors.mainWhite};
      .divider {
        background: ${styles.colors.mainBlueColor};
      }
      /* as I am hovering shift it to right by adding padding to left */
      /* padding:0.5rem 1.2rem 0.5rem 1.6rem; */
    }
  }
  .divider {
    margin-top: 0.2rem;
    width: 80%;
  }
  /* Medium and up Screen */
  @media (min-width: 768px) {
    font-size: 1.2rem;
    height: auto;
    display: flex;
    margin: 0 auto;
    .divider {
      display: none;
    }
    .quote-li {
      display: none;
    }

    .nav-link {
      display: block;
      text-decoration: none;
      font-size: 0.95rem;
      padding: 0.5rem 0.7rem 0.5rem 0.7rem;
      color: ${styles.colors.mainGrey};
      /* font-weight:700; */
      text-transform: uppercase;
      cursor: pointer;
      line-height: 1.7rem;
      /* transition: all 0.2s linear; */
      /* nested hover of the nav-link */
      &:hover {
        background: ${styles.colors.mainBlueColor};
        color: ${styles.colors.mainWhite};
        /* as I am hovering shift it to right by adding padding to left */
        /* padding:0.5rem 1.2rem 0.5rem 1.6rem; */
      }
    }

    /* LargeScreen */
  }

  @media (min-width: 992px) {
    font-size: 1.5rem;
    li {
      list-style-type: none;
    }
  }
`;
