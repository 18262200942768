import React from "react";
import styled from "styled-components";
import defaultImg from "../../utils/img/bcg/servicesbcg.jpg";

import parallexImg from "../../utils/img/bcg/contactBcg.jpg";
// import parallex1Icon11 from "../../utils/img/parallex1.svg";
// import parallexImg from "../../utils/img/parallexImg.jpg";
// import { styles } from "../../utils/";

/******************************/
/**    Functions per page
 *  Hear instead of classes we defined functions in the same Js file
 *  since they have the same CSS and character and then we export them*/
/******************************/
function HomeHeader({ img, children }) {
  return <IndexHeader img={img}>{children}</IndexHeader>;
}
function ContactHeader({ img, children }) {
  return <ContactSC img={img}>{children}</ContactSC>;
}
function ServicesHeader({ img, children }) {
  return <ServicesSC img={img}>{children}</ServicesSC>;
}
function SolutionsHeader({ img, children }) {
  return <SolutionsSC img={img}>{children}</SolutionsSC>;
}
function BlogHeader({ img, children }) {
  return <BlogSC img={img}>{children}</BlogSC>;
}
function CareersHeader({ img, children }) {
  return <CareersSC img={img}>{children}</CareersSC>;
}
function ParallexHeader({ img, children }) {
  return (
    <ParallexHeadersc img={img}>
      <div className="card-custom">{children}</div>
    </ParallexHeadersc>
  );
}

/******************************/
//   CSS Styled Components
/******************************/

const IndexHeader = styled.header`

  /* to avoide content overlay when navbar is fixed  */
  margin-top:4rem;
  padding-left:2rem;
  /* 100% of the screen minus the height of the navbar */
  min-height: calc(100vh - 55.78px);
  
  width: 100%;
  /* height: 100%; */
  background-image: url(${props => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(${props =>
    props.img}) center/cover fixed no-repeat; */
  /* background:url(${props => props.img}) center/cover fixed no-repeat; */

  display: flex;
  justify-content: left;
  align-items: center;
  font-weight: 700;
  color: white;
  @media (min-width: 768px) {
   margin-top:3.5rem;
  }
  @media (max-width: 1024px) {
    /** Fixed-backgrounds have huge repaint cost and decimate scrolling performance, which is, I believe, why it was disabled.*/
    background-attachment: scroll;
  }
`;
/** all header pages have same attributes like IndexHeader so StyleComp provids
 * such away to reuse the already created component IndexHeader
 */
const DefaultHeader = styled(IndexHeader)`
  min-height: 65vh;
  height: 100%;
  background-image: url(${props => props.img});
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; */
`;

const ServicesSC = styled(DefaultHeader)`
/* background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(${props =>
  props.img}) center/cover fixed no-repeat; */
  background-color: rgba(255, 153, 0, 0.85);
  background-blend-mode: multiply;
`;
const SolutionsSC = styled(DefaultHeader)`
  background-color: rgba(11, 68, 200, 0.8);
  background-blend-mode: multiply;
`;
const ContactSC = styled(DefaultHeader)`
  background-color: rgba(100, 36.5, 4.3, 0.44);
  background-blend-mode: multiply;
`;
const CareersSC = styled(DefaultHeader)`
  background-color: rgba(117, 5, 198, 0.75);
  /* background-color: rgba(0, 74.5, 22.4, 0.82); */
  background-blend-mode: multiply;
`;
const BlogSC = styled(DefaultHeader)`
  background-color: rgba(255, 64, 128, 0.9);
  background-blend-mode: multiply;
`;

/** Parallex view with centered text inside it. It acts as a divider in pages */

const ParallexHeadersc = styled(IndexHeader)`
  min-height: 45vh;
  height: 100%;

  /* Inherited all IndexHeader background properties and Media quireis */
  background: url(${props => props.img}) 50% / cover no-repeat;

  background-color: rgba(255, 153, 0, 0.35);
  background-blend-mode: multiply;
  /* background-color: rgba(255, 64, 128, 0.9);
  background-blend-mode: multiply; */

  padding: 4rem;
  justify-content: center;
  align-items: center;

  .card-custom {
    background: white;
    /* opacity: 0.; */
    /* filter: blur(1px); */
  }
`;

/******************************/
//    Default props
/******************************/
HomeHeader.defaultProps = {
  img: defaultImg
};
ServicesHeader.defaultProps = {
  img: defaultImg
};
SolutionsHeader.defaultProps = {
  img: defaultImg
};

BlogHeader.defaultProps = {
  img: defaultImg
};
CareersHeader.defaultProps = {
  img: defaultImg
};
ContactHeader.defaultProps = {
  img: defaultImg
};
ParallexHeader.defaultProps = {
  img: parallexImg
  // img: defaultImg
};

export {
  HomeHeader,
  ServicesHeader,
  SolutionsHeader,
  BlogHeader,
  CareersHeader,
  ContactHeader,
  ParallexHeader
};
