import React, { Component } from "react";
// import { FaInstagram, FaTwitter, FaFacebook } from "react-icons/fa";
import styled from "styled-components";
import { styles, NavButton } from "../../utils/";
import { Link } from "gatsby";
export default class NavbarIcons extends Component {
  state = {
    // icons: [
    //   {
    //     id: 1,
    //     path: "https://gby-demo-zhrsh.netlify.com/",
    //     icon: <FaFacebook className="icon facebook-icon" />
    //   },
    //   {
    //     id: 2,
    //     path: "https://gby-demo-zhrsh.netlify.com/",
    //     icon: <FaTwitter className="icon twitter-icon" />
    //   },
    //   {
    //     id: 1,
    //     path: "https://www.instagram.com/",
    //     icon: <FaInstagram className="icon instagram-icon" />
    //   }
    // ]
  };
  render() {
    return (
      <IconWrapper>
        <Link to="/quote/" className="quote-li">
          <NavButton>Get Quote</NavButton>
        </Link>
      </IconWrapper>
    );
  }
}

const IconWrapper = styled.div`
  /* Hide on small screen */

  .btn-smal {
    /* background: #1476f2 !important; */
  }
  display: none;
  /* Old was 777 */
  @media (min-width: 796px) {
    /* show on big screen */
    display: flex;
    /* width: 10rem; */
    justify-content: space-around;
  }

  .icon {
    font-size: 2rem;
    cursor: pointer;
    ${styles.transFunction(undefined, "0.4s")};
    &:hover {
      color: ${styles.colors.mainYellow};
    }
  }
  .facebook-icon {
    color: #3b579d;
  }
  .instagram-icon {
    color: #da5f53;
  }
  .twitter-icon {
    color: #3ab7f0;
  }
`;
