import React, { Component } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { FaAlignRight } from "react-icons/fa";
import logo from "../../utils/img/icon-500.png";
import { styles } from "../../utils/";

export default class NavbarHeader extends Component {
  render() {
    const { handleNavbar } = this.props;
    return (
      <HeaderWrapper>
        <Link to="/">
          <img src={logo} alt="Xbound" className="logo-img" />
        </Link>
        <div className="logo-title-div bgColor">
          <span className="logo-title"> Xbound</span>
        </div>

        <FaAlignRight className="toggle-icon" onClick={handleNavbar} />
      </HeaderWrapper>
    );
  }
}
/************************************/
/**         HeaderWrapper SC        */
/************************************/
const HeaderWrapper = styled.div`
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .logo-title-div{
    display:block;
    font-size:1.4rem;
    font-weight:600;
    letter-spacing:0.12rem;
    text-transform:uppercase;
    color:${styles.colors.mainBlueColor};
    /* margin: 0rem auto; */ 
  }
  .logo-img {
    height: 3.5rem;
    width: 3.5rem;
    /* animation: App-logo-spin infinite 20s linear; */
    /* height: 40vmin; */
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .toggle-icon {
    /* rem is releative size to the root, so 1.75 means 1.75 time bigger than
    the font size of the root */
    font-size: 2rem;
    /* color: ${styles.colors.mainYellow}; */
    color: ${styles.colors.mainBlueColor};
    cursor: pointer;
  }
  @media (min-width: 768px) {
    .toggle-icon {
      display: none;
    }
    .logo-img {
      height: 4rem;
      width: 4rem;
    }
  }
`;
